import { Routes } from '@angular/router';
import {HomeComponent} from './home/home.component';
import {AgencyComponent} from "./agency/agency.component";
import {ContactComponent} from "./contact/contact.component";
export const routes: Routes = [

    { path: '', redirectTo: '/accueil', pathMatch: 'full' },
    {
        path: 'accueil',
        loadComponent: () =>
            import('./home/home.component')
                .then(mod => mod.HomeComponent),
    },
    {
        path: 'agence/:name/:id',
        loadComponent: () =>
            import('./agency/agency.component')
                .then(mod => mod.AgencyComponent),
    },
    {
        path: 'credit-impot',
        loadComponent: () =>
            import('./impots/impots.component')
                .then(mod => mod.ImpotsComponent),
    },
    {
        path: 'recrutement',
        loadComponent: () =>
            import('./job/job.component')
                .then(mod => mod.JobComponent),
    },
    {
        path: 'contact',
        loadComponent: () =>
            import('./contact/contact.component')
                .then(mod => mod.ContactComponent),
    },
    {
        path: 'qualité',
        loadComponent: () =>
            import('./quality/quality.component')
                .then(mod => mod.QualityComponent),
    }

];
